import {useQuery} from '@apollo/client';
import axios, {AxiosError, AxiosResponse} from 'axios';
import React, {useEffect, useState, FC} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {getPriceFormat} from 'shared/lib/utils';
import {useAppSelector} from 'shared/store';
import {showAlert} from 'shared/ui/Alert';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {Head} from 'shared/ui/Head';
import {IconButton} from 'shared/ui/IconButton';
import {Layout} from 'shared/ui/Layout';
import {Modal} from 'shared/ui/Modal';
import {RadioButton} from 'shared/ui/RadioButton';
import {Seo} from 'shared/ui/Seo';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import {Skeleton} from 'shared/ui/Skeleton';
import {PageHeader} from 'shared/ui/PageHeader';
import {Image} from 'shared/ui/Image';
import {Divider} from 'shared/ui/Divider';
import {useColors} from 'shared/lib/hooks';
import {CircleArrowUp} from 'shared/icons/CircleArrowUp';
import {Support} from 'shared/icons/Support';
import Xv2 from 'shared/icons/Xv2';
import {UserCardToken} from 'shared/__generated__/graphql';
import {CircleAlert} from 'shared/icons/CircleAlert';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {IPaymentResponse} from 'entities/tariff';
import {Popup} from 'shared/ui/Popup';

const GET_COMPANY = gql(`
  query CompanyUserCard($companyId: String!, $getAppByIdId: String!) {
    company(id: $companyId) {
      id
      user_card_token_id
      companyAccountInfo {
        city {
          id
          name
        }
        address
        company_name
        office
        post_index
        comment
        email
        name
      }
    }
    me {
      id
      card_token {
        id
        last_four_digits
        first_six_digits
      }
    }
    getAppById(id: $getAppByIdId, company_id: $companyId) {
      id
      name
      app_subscription {
        id
        price
      }
      darkImage {
        id
        url
        url_256
      }
      app_url
      key
      is_subscribed(company_id: $companyId)
      short_description
      image {
        id
        url_256
        url
      }
      description
      instruction_link
    }
  }
`);

const GET_MY_PHONE = gql(`
  query MeMyPhone {
    me {
      id
      phone
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  padding-bottom: 64px;
  gap: 16px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WebSocketWhatsApp: FC<{
  url?: string | null;
}> = ({url}) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const tokenAuth = useAppSelector(state => state.auth.token);
  const [instance, setInstance] = useState<{
    customerId: number;
    token: string;
    id: number;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [qrcode, setQrcode] = useState<string | null>(null);

  useEffect(() => {
    if (instance?.token && loading) {
      const URL = `wss://msg-farmer.pushsms.ru/socket/websocket?token=${instance.token}&vsn=2.0.0`;
      const socket = new WebSocket(URL);
      console.log('socket', socket);
      socket.onopen = () => {
        console.log('onopen');
        if (socket.readyState === 1) {
          socket.send(
            `["1", "1", "room:v1:whats_app:auth:${instance.customerId}", "phx_join", {}]`,
          );
        }
      };

      socket.onmessage = event => {
        console.log('onmessage', event.data);
        if (
          typeof event.data === 'string' &&
          event.data.includes('"qr_code":"')
        ) {
          const str = event.data;
          setQrcode(
            str.substring(
              str.indexOf('qr_code":"') + 10,
              str.lastIndexOf('"}]'),
            ),
          );

          if (str.includes('"auth": true')) {
            showAlert('success', 'Авторизован');
            socket.close();
          }
        }
      };

      socket.onerror = event => {
        console.log('onerror', event);
      };

      socket.onclose = () => {
        console.log('onclose');
        setQrcode(null);
        setLoading(false);
      };
    }
  }, [instance?.customerId, instance?.token, loading, url]);

  return (
    <Flex direction="column" gap={24}>
      {loading ? (
        <Text>Пожалуйста, подождите. Идет загрузка QR-кода...</Text>
      ) : null}
      {qrcode && (
        <Flex direction="column" gap={24} justifyContent="center">
          <Text typography="subHead14Medium">Войдите через QR-код</Text>
          <Flex direction="column" alignItems="center">
            <QRCode value={qrcode} size={256} />
          </Flex>
        </Flex>
      )}
      <Button
        size="large"
        loading={loading}
        disabled={loading}
        onClick={async () => {
          try {
            setLoading(true);
            setInstance(null);
            const response = await requestJSON({
              url: 'chatpush/getInstance',
              token: tokenAuth!,
              body: JSON.stringify({
                company_id: companyId!,
              }),
            });
            console.log('response instance', response);
            setInstance({
              customerId: response.data.response.instance.customer_id ?? 0,
              token: response.data.token,
              id: response.data.response.instance.id ?? 0,
            });
          } catch (e) {
            console.log('e instance', e);
            if (e instanceof AxiosError) {
              showAlert('error', e.message);
            }
          }
        }}>
        Начать
      </Button>
    </Flex>
  );
};

const WebSocketTelegram: FC<{
  url?: string | null;
}> = ({url}) => {
  const companyId = useAppSelector(state => state.company.data?.id);
  const tokenAuth = useAppSelector(state => state.auth.token);
  const [instance, setInstance] = useState<{
    customerId: number;
    token: string;
    id: number;
  } | null>(null);
  const {data} = useQuery(GET_MY_PHONE);
  const myPhone = data?.me?.phone;
  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState('');
  const [phoneVerify, setPhoneVerify] = useState(false);

  const [code, setCode] = useState('');
  const [codeVerify, setCodeVerify] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordVerify, setPasswordVerify] = useState(false);
  const [status, setStatus] = useState<
    'wait_code' | 'wait_password' | 'wait_phone' | null
  >(null);

  useEffect(() => {
    if (instance?.token && url) {
      const URL = url + `?token=${instance.token}&vsn=2.0.0`;

      const socket = new WebSocket(URL);

      socket.onopen = () => {
        if (socket.readyState === 1) {
          socket.send(`["1","1","room:v1:telegram:auth","phx_join",{}]`);
        }
      };

      socket.onmessage = event => {
        if (
          typeof event.data === 'string' &&
          event.data.includes('"status":"wait_phone"')
        ) {
          setStatus('wait_phone');
          if (phoneVerify) {
            socket.send(
              `["1", "1", "room:v1:telegram:auth", "send_phone", {"phone": "${phone}"}]`,
            );
          }
        }

        if (
          typeof event.data === 'string' &&
          event.data.includes('"status":"wait_code"')
        ) {
          setStatus('wait_code');
          if (codeVerify) {
            socket.send(
              `["1", "1", "room:v1:telegram:auth", "send_code", {"code": "${code}"}]`,
            );
          }
        }

        if (
          typeof event.data === 'string' &&
          event.data.includes('"status":"wait_password"')
        ) {
          setStatus('wait_password');
          if (passwordVerify) {
            socket.send(
              `["1", "1", "room:v1:telegram:auth", "send_password", {"password": "${password}"}]`,
            );
          }
        }

        if (event.data.includes('"status":"authorized"')) {
          setStatus(null);
          setPhoneVerify(false);
          setCodeVerify(false);
          setPasswordVerify(false);
          setPhone('');
          setCode('');
          setPassword('');
          showAlert('success', 'Аккаунт добавлен');
          socket.close();
        }
      };

      socket.onerror = event => {
        console.log('onerror', event);
      };

      socket.onclose = () => {
        setStatus(null);
        setPhoneVerify(false);
        setCodeVerify(false);
        setPasswordVerify(false);
        setPhone('');
        setCode('');
        setPassword('');
        setLoading(false);
      };

      return () => {
        socket.close();
      };
    } else {
      return () => {};
    }
  }, [
    code,
    codeVerify,
    instance?.customerId,
    myPhone,
    password,
    passwordVerify,
    phone,
    phoneVerify,
    instance?.token,
    url,
  ]);

  return (
    <Flex direction="column" gap={16}>
      {status === null && (
        <Button
          size="large"
          loading={loading}
          onClick={async () => {
            try {
              setLoading(true);
              setInstance(null);
              const response = await requestJSON({
                url: 'chatpush/getInstance',
                token: tokenAuth!,
                body: JSON.stringify({
                  company_id: companyId!,
                }),
              });
              setInstance({
                customerId: response.data.response.instance.customerId ?? 0,
                token: response.data.token,
                id: response.data.response.instance.id ?? 0,
              });
            } catch (e) {
              if (e instanceof AxiosError) {
                showAlert('error', e.message);
              }
            }
          }}>
          Начать
        </Button>
      )}
      {status === 'wait_phone' && (
        <>
          <TextInput
            label="Введите номер телефона Telegram"
            value={phone}
            onChange={setPhone}
          />
          <Button size="large" onClick={() => setPhoneVerify(true)}>
            Подтвердить
          </Button>
        </>
      )}
      {status === 'wait_code' && (
        <>
          <Text>Подтвердите код</Text>
          <TextInput
            label="Введите код"
            type="number"
            value={code}
            onChange={setCode}
          />
          <Button size="large" onClick={() => setCodeVerify(true)}>
            Подтвердить
          </Button>
        </>
      )}
      {status === 'wait_password' && (
        <>
          <Text>Подтвердите код</Text>
          <TextInput
            label="Введите пароль"
            value={password}
            onChange={setPassword}
            type="password"
          />
          <Button size="large" onClick={() => setPasswordVerify(true)}>
            Подтвердить
          </Button>
        </>
      )}
    </Flex>
  );
};

export const ChatApp = () => {
  const params = useParams();
  const {title, variant} = useLocation().state;
  const id = params.id!;
  const companyId = useAppSelector(state => state.company.data?.id);
  const {data, refetch, loading} = useQuery(GET_COMPANY, {
    variables: {
      companyId: companyId!,
      getAppByIdId: id,
    },
  });
  const [modalUnsubscribe, setModalUnsubscribe] = useState(false);
  const subscription_price = data?.getAppById?.app_subscription?.price ?? 0;
  const cardTokens = data?.me?.card_token ?? [];
  const user_card_token_id = data?.company?.user_card_token_id;
  const findCard = cardTokens.find(card => card.id === user_card_token_id);
  const [paymnetVisible, setPaymentVisible] = useState(false);
  const colors = useColors();
  const navigate = useNavigate();

  return (
    <Layout>
      <Seo title={title} />
      <Wrapper>
        <PageHeader title={title} variant="layout" back />
        {loading ? (
          <SkeletonChatApp />
        ) : (
          <Content gap="24px" style={{marginTop: 8}}>
            <Flex gap={24} alignItems="center">
              <Image
                width={80}
                height={80}
                src={
                  data?.getAppById?.image?.url_256 ??
                  data?.getAppById?.image?.url
                }
              />
              <Flex direction="column" gap={8}>
                <Text typography="title20">{data?.getAppById?.name}</Text>
                <Text typography="text16Regular">
                  {data?.getAppById?.short_description}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex direction="column" gap={8}>
              {data?.getAppById?.is_subscribed ? (
                <>
                  {variant === 'telegram' ? (
                    <WebSocketTelegram url={data?.getAppById?.app_url} />
                  ) : (
                    <WebSocketWhatsApp url={data?.getAppById?.app_url} />
                  )}
                </>
              ) : (
                <></>
              )}
              <Button
                size="large"
                style={{
                  backgroundColor: data?.getAppById?.is_subscribed
                    ? colors.mainLight50
                    : colors.mainPrimary,
                  color: data?.getAppById?.is_subscribed
                    ? colors.mainPrimary
                    : colors.white,
                  gap: 8,
                }}
                onClick={async () => {
                  if (data?.getAppById?.is_subscribed) {
                    setModalUnsubscribe(true);
                  } else {
                    setPaymentVisible(true);
                  }
                }}>
                {data?.getAppById?.is_subscribed === true ? (
                  'Отменить подписку'
                ) : (
                  <>
                    <CirclePlus size={24} />
                    Подключить подписку
                  </>
                )}
              </Button>
            </Flex>
            {!data?.getAppById?.is_subscribed && (
              <Text
                typography="subHead14Regular"
                color="textTertiary"
                align="center">
                Подписка будет продлеваться автоматически. <br />
                Подписку можно отменить в любое время
              </Text>
            )}
            <Flex direction="column" gap={16}>
              <Text typography="text16Semibold">Описание</Text>
              <Text color="textTertiary">{data?.getAppById?.description}</Text>
            </Flex>
            {data?.getAppById?.instruction_link && (
              <Button
                onClick={() => {
                  window.open(
                    data?.getAppById?.instruction_link ?? '',
                    '_blank',
                  );
                }}
                variant="outline"
                style={{gap: 8}}>
                Инструкция по подключению
                <CircleArrowUp size={24} />
              </Button>
            )}
            <Button
              variant="light"
              size="large"
              style={{
                gap: 8,
                backgroundColor: colors.fillPrimary,
                color: colors.textPrimary,
              }}
              onClick={() => navigate('/support')}>
              <Support color={colors.textPrimary} /> Написать в чат
            </Button>
          </Content>
        )}
        <ModalPayment
          visible={paymnetVisible}
          onClose={() => setPaymentVisible(false)}
          findCard={findCard}
          price={subscription_price}
          onSuccess={() => refetch()}
          appId={id}
        />
        <ModalUnsubscribe
          id={data?.getAppById?.id}
          visible={modalUnsubscribe}
          onClose={() => setModalUnsubscribe(false)}
          name={data?.getAppById?.name ?? ''}
          onUpdate={() => {
            refetch();
            setModalUnsubscribe(false);
          }}
        />
      </Wrapper>
    </Layout>
  );
};

interface requestJSONProps {
  token?: string | null;
  body?: BodyInit;
  url?: string;
}

const requestJSON = async ({
  url = 'subscribe',
  token,
  body,
}: requestJSONProps): Promise<AxiosResponse> => {
  const response = await axios({
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : null,
    },
    url: process.env.REACT_APP_DOMAIN + `/api/app/${url}`,
    data: body,
    validateStatus: function (status) {
      return status < 400;
    },
  });
  return response;
};

type PaymentsError = {
  CardNumber: string | undefined;
  cvv: string | undefined;
  expDateMonth: string | undefined;
};

const Loader = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'flex' : 'none')};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.bgPrimary};
  border: none;
`;

const getErrorMessage = (errors: PaymentsError) => {
  switch (true) {
    case !!errors.CardNumber:
      showAlert('error', errors.CardNumber ?? '');
      break;
    case !!errors.cvv:
      showAlert('error', errors.cvv ?? '');
      break;
    case !!errors.expDateMonth:
      showAlert('error', errors.expDateMonth ?? '');
      break;
    default:
      showAlert('error', 'Ошибка вводе данных');
      break;
  }
};

const ModalPayment = ({
  visible,
  onClose,
  findCard,
  price,
  appId,
  onSuccess,
}: {
  visible: boolean;
  onClose: () => void;
  findCard?: Pick<
    UserCardToken,
    'id' | 'first_six_digits' | 'last_four_digits'
  > | null;
  price: number;
  appId?: string;
  onSuccess: () => void;
}) => {
  const [paymentType, setPaymentType] = useState<'current' | 'new'>(
    findCard ? 'current' : 'new',
  );
  const token = useAppSelector(state => state.auth.token);
  const companyId = useAppSelector(state => state.company.data?.id);
  const colors = useColors();

  const [cardNumber, setCardNumber] = useState('');
  const [cardDate, setCardDate] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [loading, setLoading] = useState(false);
  const [cloudPaymentsUrl, setCloudPaymentsUrl] = useState<string | null>(null);
  const [iframeStatus, setIframeStatus] = useState<
    'default' | 'cloud' | 'answer'
  >('default');
  const [answer, setAnswer] = useState('');
  const [requestCount, setRequestCount] = useState(0);

  type PaymentsError = {
    CardNumber: string | undefined;
    cvv: string | undefined;
    expDateMonth: string | undefined;
  };

  const getCryptogram = async () => {
    try {
      // TODO: fix eslint error checkout script
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const checkout = new cp.Checkout({
        publicId: 'pk_5d95d4c511a507abd553160c670a6',
      });
      const filteredNumber = cardNumber.replace(/ |_/g, '');
      const date = cardDate.split(' / ');
      const fieldValues = {
        cvv: cardCVV,
        cardNumber: filteredNumber,
        expDateMonth: date[0],
        expDateYear: date[1],
      };
      const cryptogram = await checkout.createPaymentCryptogram(fieldValues);
      return cryptogram;
    } catch (errors) {
      getErrorMessage(errors as PaymentsError);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        style={{left: `calc((100% - 644px) / 2)`, width: 644, padding: 24}}
        backdropVariant="overlay"
        onClose={() => onClose()}>
        <Flex direction="column">
          <Head style={{justifyContent: 'space-between'}}>
            <Text typography="title20">Оплата</Text>
            <IconButton size={40} onClick={onClose}>
              <Xv2 />
            </IconButton>
          </Head>
          <Flex direction="column" gap={16}>
            <Flex gap={8} style={{marginBottom: 8}}>
              <Text color="textTertiary" style={{flexDirection: 'row'}}>
                Стоимость подписки —{' '}
              </Text>
              <Text typography="text16Semibold">
                {getPriceFormat(price)} х 1 мес.
              </Text>
            </Flex>
            {findCard ? (
              <>
                <TextInput
                  label="Ваша карта"
                  value={'**** **** **** ' + findCard.last_four_digits}
                  onClick={() => {
                    setPaymentType('current');
                    setCardNumber('');
                    setCardCVV('');
                    setCardDate('');
                  }}
                  disabled
                  style={{color: colors.textPrimary, pointerEvents: 'none'}}
                  leftElement={
                    <RadioButton
                      style={{marginRight: 8}}
                      selected={paymentType === 'current'}
                    />
                  }
                />
                <Flex
                  direction="column"
                  gap={8}
                  style={{
                    backgroundColor: colors.fillPrimary,
                    padding: 16,
                    borderRadius: 20,
                  }}>
                  <RadioButton
                    selected={paymentType === 'new'}
                    title="Новый способ оплаты"
                    onChange={() => setPaymentType('new')}
                    labelPosition="right"
                  />
                  {paymentType === 'new' ? (
                    <>
                      <TextInput
                        backgroundColor={colors.bgPrimary}
                        label="Номер карты"
                        value={cardNumber}
                        onChange={setCardNumber}
                        mask="9999 9999 9999 9999"
                      />
                      <Flex direction="row" gap={8}>
                        <TextInput
                          flex={1}
                          backgroundColor={colors.bgPrimary}
                          label="ММ / ГГ"
                          mask="99 / 99"
                          value={cardDate}
                          onChange={setCardDate}
                        />
                        <TextInput
                          flex={1}
                          backgroundColor={colors.bgPrimary}
                          type="password"
                          label="CVV"
                          mask="999"
                          value={cardCVV}
                          onChange={setCardCVV}
                        />
                      </Flex>
                    </>
                  ) : undefined}
                </Flex>
              </>
            ) : (
              <>
                <TextInput
                  label="Номер карты"
                  value={cardNumber}
                  onChange={setCardNumber}
                  mask="9999 9999 9999 9999"
                />
                <Flex gap={8}>
                  <TextInput
                    label="ММ / ГГ"
                    mask="99 / 99"
                    width="302px"
                    value={cardDate}
                    onChange={setCardDate}
                  />
                  <TextInput
                    label="CVV"
                    mask="999"
                    width="302px"
                    value={cardCVV}
                    onChange={setCardCVV}
                  />
                </Flex>
              </>
            )}
            <Button
              size="large"
              loading={loading}
              style={{marginTop: 8}}
              onClick={async () => {
                try {
                  setLoading(true);
                  if (paymentType === 'new') {
                    const cryptogramPacket = await getCryptogram();
                    const body = {
                      app_id: appId!,
                      company_id: companyId!,
                      cardData: cryptogramPacket,
                    };
                    const response = await requestJSON({
                      token: token,
                      body: JSON.stringify(body),
                    });
                    const data = response.data as IPaymentResponse;
                    const dataRes = {
                      paReq: data.response.Model.PaReq,
                      acsUrl: data.response.Model.AcsUrl,
                      TermUrl:
                        process.env.REACT_APP_DOMAIN +
                        '/api/app/callback?subscription_id=' +
                        data.subscription_id,
                      MD: data.response.Model.TransactionId,
                    };
                    const first = encodeURIComponent(dataRes.paReq);
                    const second = encodeURIComponent(dataRes.TermUrl);
                    const three = encodeURIComponent(dataRes.MD);
                    const resUrl =
                      dataRes.acsUrl +
                      '&PaReq=' +
                      first +
                      '&TermUrl=' +
                      second +
                      '&MD=' +
                      three;
                    setCloudPaymentsUrl(resUrl);
                    setAnswer(dataRes.TermUrl);
                  } else {
                    const body = {
                      app_id: appId!,
                      company_id: companyId!,
                      user_card_token_id: findCard ? findCard.id : undefined,
                    };
                    await requestJSON({
                      token: token,
                      body: JSON.stringify(body),
                    });
                  }
                  onSuccess();
                  onClose();
                } catch (e) {
                  console.log('e', e);
                } finally {
                  setLoading(false);
                }
              }}>
              Оплатить
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <Popup
        style={{
          width: '50%',
          height: '70%',
          backgroundColor: 'white',
          padding: 0,
        }}
        visible={!!cloudPaymentsUrl}
        onClose={() => {
          setCloudPaymentsUrl(null);
        }}>
        <Flex
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            borderRadius: 40,
            padding: '0 30px',
            border: 'none',
          }}
          direction="column"
          gap={24}>
          {cloudPaymentsUrl !== null ? (
            <>
              <Loader show={iframeStatus === 'answer'} />
              <iframe
                id="iframeId"
                seamless
                width={'100%'}
                height={'100%'}
                onError={() => {
                  setCloudPaymentsUrl(null);
                  setIframeStatus('default');
                  showAlert('error', 'Ошибка при оплате');
                }}
                src={cloudPaymentsUrl}
                style={{border: 'none', backgroundColor: 'transparent'}}
                onLoad={async () => {
                  setRequestCount(prevCount => prevCount + 1);
                  if (requestCount < 2) {
                    return;
                  }
                  try {
                    setIframeStatus('answer');
                    const response = await axios({
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                      },
                      url: answer,
                      validateStatus: function (status) {
                        return status < 400;
                      },
                    });
                    console.log('3DSECURE', response.data.status);
                    if (response.data.status == 'success') {
                      setCloudPaymentsUrl(null);
                      onSuccess();
                      onClose();
                      showAlert('success', 'Оплата прошла успешно!');
                    } else if (response.data.status == 'cancelled') {
                      setCloudPaymentsUrl(null);
                      setRequestCount(0);
                      showAlert('error', 'Ошибка при оплате');
                    } else {
                      setCloudPaymentsUrl(null);
                      setRequestCount(0);
                      showAlert(
                        'error',
                        'Такой номер телефона уже существует на другом инстансе.',
                      );
                    }
                  } catch (error) {
                    showAlert('error', 'Ошибка при оплате');
                  } finally {
                    setIframeStatus('default');
                  }
                }}
              />
            </>
          ) : null}
        </Flex>
      </Popup>
    </>
  );
};

const SkeletonChatApp = () => {
  return (
    <Content style={{marginTop: 8}}>
      <Skeleton height={240}>
        <rect width={150} height={24} rx={8} ry={8} x={0} y={0} />
        <rect width={100} height={20} rx={8} ry={8} x={0} y={48} />
        <rect width={'100%'} height={62} rx={18} ry={18} x={0} y={92} />
        <rect width={'100%'} height={62} rx={18} ry={18} x={0} y={178} />
      </Skeleton>
    </Content>
  );
};

const ModalUnsubscribe = ({
  visible,
  onClose,
  name,
  id,
  onUpdate,
}: {
  visible: boolean;
  onClose: () => void;
  name: string;
  id?: string;
  onUpdate: () => void;
}) => {
  const colors = useColors();
  const [unsubscribed, setUnscribed] = useState(false);
  const companyId = useAppSelector(state => state.company.data?.id);
  const token = useAppSelector(state => state.auth.token);
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      visible={visible}
      style={{left: `calc((100% - 472px) / 2)`, width: 472, padding: 24}}
      backdropVariant="overlay"
      onClose={() => {
        onClose();
        setUnscribed(false);
      }}>
      <Flex direction="column">
        <Head style={{justifyContent: 'space-between'}}>
          <Text typography="title20">
            {unsubscribed ? 'Ваша подписка отменена' : 'Отмена подписки'}
          </Text>
          <IconButton size={40} onClick={onClose}>
            <Xv2 />
          </IconButton>
        </Head>
        <Flex
          style={{
            border: unsubscribed ? 'none' : `1px solid ${colors.borderPrimary}`,
            borderRadius: 20,
            padding: unsubscribed ? 0 : 16,
            gap: 16,
            marginBottom: 24,
          }}>
          {unsubscribed ? (
            <></>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              style={{
                minWidth: 40,
                maxHeight: 40,
                borderRadius: 20,
                backgroundColor: colors.fillPrimary,
              }}>
              <CircleAlert color={colors.textTertiary} size={24} />
            </Flex>
          )}
          <Text typography="subHead14Regular">
            {unsubscribed
              ? `Вы успешно отменили подписку ${name} от ChatPush`
              : 'Вам доступна подписка до конца оплаченного периода - 17 ноября 2024 года.'}
          </Text>
        </Flex>
        <Button
          variant={unsubscribed ? 'outline' : 'filled'}
          size="large"
          loading={loading}
          onClick={async () => {
            if (unsubscribed) {
              onClose();
              setUnscribed(false);
            } else {
              try {
                setLoading(true);
                await requestJSON({
                  url: 'unsubscribe',
                  token: token!,
                  body: JSON.stringify({
                    company_id: companyId!,
                    app_id: id!,
                  }),
                });
                setUnscribed(true);
                onUpdate();
              } catch (e) {
                if (e instanceof AxiosError) {
                  showAlert('error', e.message);
                }
              } finally {
                setLoading(false);
              }
            }
          }}>
          {unsubscribed ? 'Закрыть' : 'Отменить'}
        </Button>
      </Flex>
    </Modal>
  );
};
