import dayjs from 'dayjs';
import {SettingSchedule, WorkDate} from 'entities/schedule';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CompanyBookingStatus, WorkingDate} from 'shared/__generated__/graphql';
import {
  StatusText,
  getName,
  getPhoneMask,
  getPriceFormat,
  getShortName,
  getTimeArray,
} from 'shared/lib/utils';
import {
  clearCreateBookingState,
  setBookingScreen,
  setCreateBookingEmployee,
  setCreateBookingWorkingDate,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {Avatar} from 'shared/ui/Avatar';
import {CloseButton} from 'shared/ui/CloseButton';
import {Flex} from 'shared/ui/Flex';
import {NavLink} from 'shared/ui/NavLink';
import {Popup} from 'shared/ui/Popup';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {TableBg} from './TableBg';
import {CircleAlert} from 'shared/icons/CircleAlert';
import {Divider} from 'shared/ui/Divider';
import {BadgeStatus} from 'shared/ui/BadgeStatus';
import {ArrowLeft2v} from 'shared/icons/ArrowLeft2v';
import {ArrowRight2v} from 'shared/icons/ArrowRight2v';

const HEIGHT_TIME = 43;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.bgPrimary};
  max-width: 100%;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  border-radius: 24px;
  padding: 0 24px 24px 16px;
  position: relative;
  margin-bottom: 24px;
`;

const EmployeeProfileWrapper = styled.div`
  height: 56px;
  width: 100%;
  position: sticky;
  top: 76px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  z-index: 98;
  background-color: ${({theme}) => theme.bgPrimary};
`;

const EmployeeProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 24px;
  margin-left: 56px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const EmployeeItemWrapper = styled.button<{$column: number}>`
  width: calc((100% - 72px) / ${({$column}) => $column});
  border: none;
  pointer-events: all;
  cursor: pointer;
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 16px;
  justify-content: center;
`;

const ScrollButton = styled.button<{
  $left?: number | string;
  $right?: number;
}>`
  width: 44px;
  height: 44px;
  background-color: ${({theme}) => theme.bgSecondary};
  border: 1px solid ${({theme}) => theme.borderPrimary};
  aspect-ratio: 1 / 1;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${({$left}) => $left}px;
  right: ${({$right}) => $right}px;
  z-index: 1;
  pointer-events: all;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.mainLight};
  }
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 56px 1fr;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -webkit-overflow-scrolling: unset;
`;

const TimeLineWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  width: 56px;
  gap: 24px;
  padding-left: 8px;
  grid-row-start: 1;
  position: sticky;
  left: 0;
  background-color: ${({theme}) => theme.bgPrimary};
  z-index: 97;
  display: flex;
  flex-direction: column;
`;

const BookingWrapper = styled.div<{columns: number; length: number}>`
  grid-column-start: 2;
  grid-column-end: 3;
  padding: 10px 0;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const Time = styled(Text)<{
  $top: number;
  $hidden?: boolean;
}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'block')};
  border: 1px solid ${({theme}) => theme.dangerPrimary};
  background-color: ${({theme}) => theme.bgPrimary};
  padding: 0 8px;
  border-radius: 18px;
  position: absolute;
  width: 48px;
  z-index: 99;
  right: 8px;
  top: ${({$top}) => $top}px;
`;

const RedLine = styled.div<{$top: number; $visible: boolean}>`
  height: 1px;
  width: calc(100% - 88px);
  left: 64px;
  top: ${({$top}) => $top}px;
  display: ${({$visible}) => ($visible ? 'flex' : 'none')};
  background-color: ${({theme}) => theme.dangerPrimary};
  position: absolute;
  z-index: 97;
`;

const PlusWrapper = styled.button<{
  $x: number | null;
  $y: number | null;
  $columns: number;
}>`
  position: absolute;
  top: ${({$y}) => $y}px;
  left: ${({$x}) => $x ?? 0}px;
  min-width: ${({$columns}) =>
    `calc((100% - 24px * ${$columns - 1}) / ${$columns})`};
  height: ${HEIGHT_TIME / 2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 0 16px;
  gap: 8px;
  border-radius: 8px;
  stroke: black;
  background-color: ${({theme}) => theme.mainPrimary50};
  pointer-events: all;
  cursor: pointer;
  z-index: 0;
  transition:
    top 100ms,
    left 100ms;
`;

const Plus = ({
  onClick,
  x,
  y,
  columns,
  length,
  time,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  x: number;
  y: number;
  columns: number;
  length: number;
  time: string;
}) => {
  return (
    <PlusWrapper
      $x={x}
      $y={y}
      $columns={Math.min(columns, length)}
      onClick={onClick}>
      <Text typography="tab10" color="textPrimary">
        {time}
      </Text>
      <Text typography="tab10" color="textPrimary">
        Новая запись
      </Text>
    </PlusWrapper>
  );
};

const StopWrapper = styled.div<{
  $height: number;
  $top: number;
  $length: number;
  $index: number;
}>`
  display: flex;
  position: absolute;
  top: ${({$top}) => $top}px;
  height: ${({$height}) => $height}px;
  background-color: ${({theme}) => theme.bgSecondary};
  align-items: center;
  justify-content: center;
  min-width: ${({$length}) =>
    `calc((100% - 24px * ${$length - 1}) / ${$length})`};
  left: ${({$length, $index}) =>
    `calc(((100% - 24px * ${$length - 1}) / ${$length} + 24px) * ${$index})`};
`;

const Stop = ({
  text,
  height,
  top,
  length,
  index,
}: {
  text: string;
  height: number;
  top: number;
  length: number;
  index: number;
}) => {
  return (
    <StopWrapper $length={length} $height={height} $top={top} $index={index}>
      <Text
        typography="text16Regular"
        color="textTertiary"
        style={{textAlign: 'center'}}>
        {text}
      </Text>
    </StopWrapper>
  );
};

const WrapperBookingPopover = styled.div<{
  $visible: boolean;
  $x: number;
  $y?: number;
  $index: number;
  $length: number;
}>`
  position: absolute;
  visibility: ${({$visible}) => ($visible ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  transition: opacity 0.1s;
  z-index: 1000;
  padding: 24px 16px;
  border-radius: 20px;
  pointer-events: all;
  cursor: pointer;
  border: 1px solid ${({theme}) => theme.borderPrimary};
  background-color: ${({theme}) => theme.bgPrimary};
  width: 322px;
  gap: 16px;
  top: ${({$y}) => $y + 'px'};
  left: ${({$x}) => $x}px;
  ${({theme}) => theme.cardShadow}
`;

const ResourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 160px;
  overflow-y: scroll;
  gap: 16px;
`;

const BookingCard = styled.div<{
  $top: number;
  $height: number;
  $status: string;
  $isVip: boolean;
  $index: number;
  $length: number;
  $bookingIndex: number;
}>`
  height: ${({$height}) => $height}px;
  padding: 4px 0 0 20px;
  left: ${({$length, $index}) =>
    `calc(((100% - 24px * ${$length - 1}) / ${$length} + 24px) * ${$index})`};
  width: ${({$length}) => `calc((100% - 24px * ${$length - 1}) / ${$length})`};
  position: absolute;
  background-color: ${({$status, theme}) =>
    $status === 'completed'
      ? theme.bgSuccess
      : $status === 'cancelled'
      ? theme.bgDanger
      : theme.bgWaiting};
  top: ${({$top}) => $top + 10}px;
  border-top: 4px solid
    ${({theme, $isVip, $status}) =>
      $isVip
        ? theme.goldPrimary
        : $status === 'completed'
        ? theme.successPrimary
        : $status === 'cancelled'
        ? theme.dangerPrimary
        : theme.waitingPrimary};
  pointer-events: all;
  cursor: pointer;
  z-index: ${({$bookingIndex}) => $bookingIndex};
`;

const statusTextColor = (status: string) => {
  switch (status) {
    case 'pending':
      return 'waitingPrimary';
    case 'cancelled':
      return 'dangerPrimary';
    case 'completed':
      return 'successPrimary';
    case 'confirmed':
      return 'waitingPrimary';
  }
};

const BookingView = ({
  booking,
  start_time,
  end_time,
  index,
  bookingindex,
  length,
  employeeName,
}: {
  booking: BookingsData;
  start_time: number;
  end_time: number;
  index: number;
  bookingindex: number;
  length: number;
  employeeName: string;
}) => {
  const interval_time = useAppSelector(
    state => state.booking.filter.interval_time,
  );
  const start =
    +booking.start.split(':')[0] * 60 + +booking.start.split(':')[1];
  const end = +booking.end.split(':')[0] * 60 + +booking.end.split(':')[1];
  const top = Math.trunc(((start - start_time) / interval_time) * HEIGHT_TIME);
  const height =
    ((Math.min(end, end_time) - start) / interval_time) * HEIGHT_TIME;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const username = useAppSelector(state => state.company.data?.username);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isPopoverCapture, setIsPopoverCapture] = useState(false);
  const resources = booking.services.map(service => service.resources).flat();

  const refBookingCard = useRef<HTMLDivElement>(null);
  return (
    <>
      <BookingCard
        id="bookingcard"
        ref={refBookingCard}
        onMouseMoveCapture={() => {
          setPopoverVisible(true);
        }}
        onMouseOutCapture={() => {
          if (isPopoverCapture === false) {
            setPopoverVisible(false);
          }
        }}
        $top={top}
        $height={height}
        $length={length}
        $status={booking.status}
        $isVip={booking.is_vip ? true : false}
        onClick={() => {
          dispatch(setBookingScreen('edit'));
          navigate(`/${username}/booking/${booking.id}`);
        }}
        $bookingIndex={bookingindex}
        $index={index}>
        <Flex
          flex={1}
          direction="column"
          gap={4}
          style={{
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}>
          <Text
            typography="subHead14Medium"
            style={{pointerEvents: 'none'}}
            color={
              booking.status === 'cancelled' ? 'dangerPrimary' : 'textPrimary'
            }>
            {booking.customer_name}
          </Text>
          <Text
            typography="footNote12Medium"
            style={{pointerEvents: 'none'}}
            color={
              booking.status === 'cancelled' ? 'dangerPrimary' : 'textPrimary'
            }>
            {getPhoneMask(booking.customer_phone)}
          </Text>
          <Text
            typography="footNote12Medium"
            style={{pointerEvents: 'none'}}
            color={statusTextColor(booking.status)}>
            {booking.price === 0 ? 'Бесплатно' : getPriceFormat(booking.price)}
          </Text>
          {height > HEIGHT_TIME * 2 ? (
            <Text
              typography="footNote12Medium"
              color={statusTextColor(booking.status)}
              style={{position: 'absolute', bottom: 7, right: 8}}>
              {booking.start}-{booking.end}
            </Text>
          ) : null}
        </Flex>
      </BookingCard>
      <WrapperBookingPopover
        onMouseMoveCapture={() => {
          setPopoverVisible(true);
          setIsPopoverCapture(true);
        }}
        onMouseOutCapture={() => {
          setIsPopoverCapture(false);
          setPopoverVisible(false);
        }}
        onClick={() => navigate(`/${username}/booking/${booking.id}`)}
        id="bookingPopover"
        $x={
          (((document.getElementById('BookingWrapper')?.offsetWidth ?? 0) -
            24 * (length - 1)) /
            length) *
            (index + 1) +
          index * 24 -
          (index === length - 1 && index !== 0
            ? ((document.getElementById('BookingWrapper')?.offsetWidth ?? 0) -
                24 * (length - 1)) /
                length +
              322
            : index === 0
            ? 0
            : 322)
        }
        $y={
          (document.getElementById('bookingPopover')?.offsetHeight ?? 0) >
          Math.trunc(Math.abs(end_time - end) / interval_time) * HEIGHT_TIME
            ? top +
              height -
              (document.getElementById('bookingPopover')?.offsetHeight ?? 0) +
              64
            : top + 10
        }
        $index={index}
        $length={length}
        $visible={popoverVisible}>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Text
            style={{pointerEvents: 'none'}}
            typography="title18"
            color="mainPrimary">
            {booking.start}-{booking.end}
          </Text>
          <BadgeStatus type={booking.status as CompanyBookingStatus}>
            {StatusText[booking.status as CompanyBookingStatus]}
          </BadgeStatus>
        </Flex>
        <Flex direction="column" gap={8}>
          <Text
            style={{pointerEvents: 'none'}}
            typography="subHead14Regular"
            color="textTertiary">
            Клиент
          </Text>
          <Text style={{pointerEvents: 'none'}} typography="text16Semibold">
            {booking.customer_name}
          </Text>
          <Text style={{pointerEvents: 'none'}} color="textTertiary">
            {getPhoneMask(booking.customer_phone)}
          </Text>
        </Flex>
        <Divider />
        <Flex direction="column" gap={8}>
          <Text
            style={{pointerEvents: 'none'}}
            typography="subHead14Regular"
            color="textTertiary">
            Сотрудник
          </Text>
          <Text style={{pointerEvents: 'none'}}>{employeeName}</Text>
        </Flex>
        <Flex direction="column" gap={8}>
          <Text
            style={{pointerEvents: 'none'}}
            typography="subHead14Regular"
            color="textTertiary">
            Ресурс
          </Text>
          <ResourceContainer>
            {resources.map(resource => (
              <Flex key={resource.id} direction="column" gap={8}>
                <Text>{resource.name}</Text>
                {!resource.is_available && (
                  <Flex gap={8} alignItems="center">
                    <CircleAlert />
                    <Text typography="subHead14Medium" color="warningPrimary">
                      Ресурс недоступен
                    </Text>
                  </Flex>
                )}
              </Flex>
            ))}
          </ResourceContainer>
        </Flex>
        <Flex direction="column" gap={8}>
          <Text
            style={{pointerEvents: 'none'}}
            typography="subHead14Regular"
            color="textTertiary">
            Услуга
          </Text>
          {booking.services.map((service, serviceIndex) => (
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              key={'service_' + serviceIndex}>
              <Text style={{pointerEvents: 'none'}}>{service.name}</Text>
              <Text
                style={{pointerEvents: 'none'}}
                typography="text16Semibold"
                color="mainPrimary">
                {getPriceFormat(service.price)}
              </Text>
            </Flex>
          ))}
        </Flex>
      </WrapperBookingPopover>
    </>
  );
};

type BookingsData = {
  start: string;
  end: string;
  id?: string | null;
  customer_id?: string | null;
  customer_name?: string | null;
  customer_phone?: string | null;
  is_vip?: boolean;
  employee_id: string;
  workingDate: Pick<WorkingDate, 'id' | 'date' | 'start_time' | 'end_time'>;
  price: number;
  status: string;
  services: {
    name: string;
    price: number;
    resources: {
      id: string;
      name: string;
      is_available?: boolean | null;
      order?: number | null;
    }[];
  }[];
};

type Props = {
  employees: {
    id: string;
    name?: string | null;
    surname?: string | null;
    profession?: string;
    online: boolean;
    avatar?: string;
    workingDates:
      | (Pick<WorkingDate, 'id' | 'date' | 'start_time' | 'end_time'> & {
          breakTimes: {id: string; start: string; end: string}[];
          employee: {
            id: string;
          };
        })
      | undefined;
  }[];
  bookings: BookingsData[];
  length: number;
  createPermission: boolean;
  employeesInfoPermission: boolean;
  editWorkSchedulePermission: boolean;
  start_time: number;
  end_time: number;
  onPrevPage?: () => void;
  onNextPage?: () => void;
  columns: number;
};

export const TimeLines = (props: Props) => {
  const {
    employees,
    createPermission,
    employeesInfoPermission,
    editWorkSchedulePermission,
    start_time,
    end_time,
    length,
    bookings,
    onPrevPage,
    onNextPage,
    columns,
  } = props;
  const [visibleModalEmployee, setVisibleModalEmployee] = useState(false);
  const [modalEmployee, setModalEmployee] = useState<{
    url?: string;
    name: string;
    id: string;
    workingDate: WorkDate;
    professsion?: string;
  } | null>(null);
  const dateRedux = useAppSelector(state => state.booking.date);
  const intervalTime = useAppSelector(
    state => state.booking.filter.interval_time,
  );
  const refBody = useRef<HTMLDivElement>(null);

  const [plusOffset, setPlusOffset] = useState<{
    x: number;
    y: number;
    width: number;
    scrollX: number;
    scrollY: number;
    time: string | null;
  }>({
    x: 0,
    y: 0,
    width: 0,
    scrollX: 0,
    scrollY: 0,
    time: null,
  });
  const [mouseMoving, setMouseMoving] = useState(true);
  const dispatch = useAppDispatch();
  const username = useAppSelector(state => state.company.data?.username);
  const navigate = useNavigate();

  useEffect(() => {
    const updateMousePosition = (event: MouseEvent) => {
      const sidebar = document.getElementById('sidebar');
      const idBookingWrapper = document.getElementById('BookingWrapper');
      const mousePositionX = event.clientX - (sidebar?.offsetWidth ?? 0) - 96;
      const idFilterWrapper = document.getElementById('FilterWrapper');
      const filterWrapperHeight = idFilterWrapper
        ? idFilterWrapper.offsetHeight + 24
        : 0;
      const columnVisible = Math.min(columns, length);
      const getWidth =
        ((idBookingWrapper?.offsetWidth ?? 0) - 24 * (columnVisible - 1)) /
        columnVisible;
      const divider = getWidth + 24;
      const x = Math.min(
        Math.trunc(mousePositionX / divider) * divider,
        divider * (length - 1),
      );
      const y = Math.max(
        Math.trunc(
          (event.clientY - 192 - filterWrapperHeight + (window.scrollY ?? 0)) /
            (HEIGHT_TIME / 2),
        ) *
          (HEIGHT_TIME / 2) +
          10,
        10,
      );
      if (mouseMoving) {
        setPlusOffset({
          ...plusOffset,
          width: getWidth,
          x,
          y: Math.min(y, (refBody.current?.offsetHeight ?? 53) - 53),
          scrollY: event.clientY,
        });
      }
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, [plusOffset, mouseMoving, columns, length]);

  useEffect(() => {
    const wheelFunction = (event: WheelEvent) => {
      if (event.deltaX) {
        event.preventDefault();
      }
    };
    window.addEventListener('wheel', wheelFunction, {passive: false});

    return () => {
      window.removeEventListener('wheel', wheelFunction);
    };
  });

  useEffect(() => {
    const mouseClicked = (event: MouseEvent) => {
      if (event.buttons === 4) {
        event.preventDefault();
      }
    };

    window.addEventListener('mousedown', mouseClicked);

    return () => {
      window.removeEventListener('mousedown', mouseClicked);
    };
  }, []);

  return (
    <Wrapper>
      <EmployeeProfileWrapper>
        <EmployeeProfileContainer>
          {onPrevPage && (
            <ScrollButton $left={0} onClick={() => onPrevPage()}>
              <ArrowLeft2v />
            </ScrollButton>
          )}

          <Container id="containerBooking">
            {employees.map(employee => (
              <EmployeeItemWrapper
                key={employee.id}
                $column={Math.min(columns, length)}
                onClick={() => {
                  setModalEmployee({
                    url: employee.avatar,
                    name: getShortName(
                      employee.name,
                      employee.surname,
                      'employee',
                    ),
                    professsion: employee.profession,
                    id: employee.id,
                    workingDate: {
                      id: employee.workingDates?.id,
                      date: employee.workingDates?.date ?? '',
                      end_time: employee.workingDates?.end_time,
                      start_time: employee.workingDates?.start_time,
                      breakTimes: employee.workingDates?.breakTimes,
                    },
                  });
                  setVisibleModalEmployee(true);
                }}>
                <Avatar url={employee.avatar} />
                <Flex
                  direction="column"
                  gap={4}
                  style={{
                    overflow: 'hidden',
                    maxWidth: 'calc(100% - 48px)',
                  }}>
                  <Text
                    typography="text16Semibold"
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                    {getShortName(employee.name, employee.surname, 'employee')}
                  </Text>
                  <Text
                    typography="subHead14Regular"
                    color="textTertiary"
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                    {employee.profession}
                  </Text>
                </Flex>
              </EmployeeItemWrapper>
            ))}
          </Container>
          {onNextPage && (
            <ScrollButton $right={0} onClick={() => onNextPage()}>
              <ArrowRight2v />
            </ScrollButton>
          )}
        </EmployeeProfileContainer>
      </EmployeeProfileWrapper>
      <Body ref={refBody}>
        <BookingWrapper
          id="BookingWrapper"
          columns={columns}
          length={length}
          onMouseOver={() => setMouseMoving(true)}
          onMouseOut={() => setMouseMoving(false)}>
          <TableBg
            start_time={start_time}
            end_time={end_time}
            columns={columns}
            length={length}
          />
          {createPermission && (
            <Plus
              time={
                Array.from(
                  Array(
                    Math.ceil((end_time - start_time) / intervalTime) * 2,
                  ).keys(),
                )
                  .map(item => {
                    const value = start_time + (item * intervalTime) / 2;
                    const hour = Math.trunc(value / 60);
                    const minute = value % 60;
                    const hourStr = hour < 10 ? `0${hour}` : hour.toString();
                    const minuteStr =
                      minute < 10 ? `0${minute}` : minute.toString();
                    return hourStr + ':' + minuteStr;
                  })
                  .find(
                    (_, index) =>
                      index ===
                      Math.trunc(((plusOffset.y ?? 0) / HEIGHT_TIME) * 2),
                  ) ?? ''
              }
              x={plusOffset.x}
              y={plusOffset.y}
              columns={columns}
              length={length}
              onClick={() => {
                const indexEmployee = Math.trunc(
                  plusOffset.x / plusOffset.width,
                );
                const employee = employees.find(
                  (_, index) => index === indexEmployee,
                );
                dispatch(clearCreateBookingState());
                dispatch(setBookingScreen('create'));

                if (employee) {
                  const time =
                    Array.from(
                      Array(
                        Math.ceil((end_time - start_time) / intervalTime) * 2,
                      ).keys(),
                    )
                      .map(item => {
                        const value = start_time + (item * intervalTime) / 2;
                        const hour = Math.trunc(value / 60);
                        const minute = value % 60;
                        const hourStr =
                          hour < 10 ? `0${hour}` : hour.toString();
                        const minuteStr =
                          minute < 10 ? `0${minute}` : minute.toString();
                        return hourStr + ':' + minuteStr;
                      })
                      .find(
                        (_, index) =>
                          index ===
                          Math.trunc(((plusOffset.y ?? 0) / HEIGHT_TIME) * 2),
                      ) ?? '';

                  dispatch(
                    setCreateBookingWorkingDate(
                      employee?.workingDates?.date +
                        ' ' +
                        time +
                        ',' +
                        employee?.workingDates?.id,
                    ),
                  );
                  dispatch(setCreateBookingEmployee(employee?.id));
                  navigate(`/${username}/create-booking`);
                }
              }}
            />
          )}

          {employees.map((employee, employeeIndex) => {
            const workingDateStart =
              +(employee.workingDates?.start_time.split(':')[0] ?? 0) * 60 +
              +(employee.workingDates?.start_time.split(':')[1] ?? 0);
            const workingDateEnd =
              +(employee.workingDates?.end_time.split(':')[0] ?? 0) * 60 +
              +(employee.workingDates?.end_time.split(':')[1] ?? 0);
            const topNowork2 =
              Math.trunc(
                ((workingDateEnd - start_time) / intervalTime) * HEIGHT_TIME,
              ) + 10;
            const height =
              ((workingDateStart - start_time) / intervalTime) * HEIGHT_TIME;
            const height2 =
              ((end_time - workingDateEnd) / intervalTime) * HEIGHT_TIME;
            return (
              <React.Fragment key={'employee_fragment_' + employee.id}>
                {employee.workingDates?.breakTimes.map(workDate => {
                  const startNumber =
                    +workDate.start.split(':')[0] * 60 +
                    +workDate.start.split(':')[1];
                  const endNumber =
                    +workDate.end.split(':')[0] * 60 +
                    +workDate.end.split(':')[1];
                  const top =
                    Math.trunc(
                      ((startNumber - start_time) / intervalTime) * HEIGHT_TIME,
                    ) + 10;
                  const height =
                    ((endNumber - startNumber) / intervalTime) * HEIGHT_TIME;

                  return (
                    <Stop
                      key={'break_' + workDate.id}
                      height={height}
                      top={top}
                      text="Перерыв"
                      index={employeeIndex}
                      length={length}
                    />
                  );
                })}
                {employee.workingDates && workingDateStart > start_time ? (
                  <Stop
                    text="Нерабочее время"
                    height={height}
                    top={10}
                    index={employeeIndex}
                    length={length}
                  />
                ) : null}
                {employee.workingDates && workingDateEnd < end_time ? (
                  <Stop
                    text="Нерабочее время"
                    height={height2}
                    top={topNowork2}
                    index={employeeIndex}
                    length={length}
                  />
                ) : null}
                {bookings
                  .filter(booking => booking.employee_id === employee.id)
                  .map((booking, bookingIndex) => (
                    <BookingView
                      key={'booking_' + booking.id}
                      booking={booking}
                      start_time={start_time}
                      end_time={end_time}
                      index={employeeIndex}
                      bookingindex={bookingIndex}
                      length={length}
                      employeeName={getName(
                        employee.name,
                        employee.surname,
                        'employee',
                      )}
                    />
                  ))}
              </React.Fragment>
            );
          })}
        </BookingWrapper>
        <TimeLineWrapper>
          {getTimeArray(intervalTime, start_time, end_time).map(
            (item, index) => (
              <Text
                key={`divider_${index}`}
                typography={
                  index % 2 === 0 ? 'subHead14Regular' : 'footNote12Regular'
                }
                color={index % 2 !== 0 ? 'textTertiary' : 'textPrimary'}>
                {item}
              </Text>
            ),
          )}
          {dayjs().isSame(dateRedux, 'date') && (
            <Time
              $hidden={
                dayjs().get('hour') * 60 + dayjs().get('minute') > end_time ||
                dayjs().get('hour') * 60 + dayjs().get('minute') < start_time
              }
              typography="footNote12Medium"
              color="dangerPrimary"
              $top={
                ((dayjs().get('hour') * 60 +
                  dayjs().get('minute') -
                  start_time) /
                  intervalTime) *
                HEIGHT_TIME
              }>
              {dayjs().format('HH:mm')}
            </Time>
          )}
        </TimeLineWrapper>
        {dayjs().isSame(dateRedux, 'date') && (
          <RedLine
            $top={
              ((dayjs().get('hour') * 60 + dayjs().get('minute') - start_time) /
                intervalTime) *
                HEIGHT_TIME +
              89
            }
            $visible={
              dayjs().get('hour') * 60 + dayjs().get('minute') >= start_time &&
              dayjs().get('hour') * 60 + dayjs().get('minute') <= end_time
            }
          />
        )}
      </Body>
      <ModalEmployee
        visible={visibleModalEmployee}
        onClose={() => setVisibleModalEmployee(false)}
        url={modalEmployee?.url}
        name={modalEmployee?.name}
        profession={modalEmployee?.professsion}
        id={modalEmployee?.id}
        workingDate={modalEmployee?.workingDate}
        employeesInfoPermission={employeesInfoPermission}
        editWorkSchedulePermission={editWorkSchedulePermission}
      />
    </Wrapper>
  );
};

const ModalEmployee = ({
  visible,
  onClose,
  url,
  id,
  name,
  workingDate,
  employeesInfoPermission,
  editWorkSchedulePermission,
  profession,
}: {
  url?: string;
  name?: string;
  id?: string;
  visible: boolean;
  onClose: () => void;
  workingDate?: WorkDate;
  employeesInfoPermission: boolean;
  editWorkSchedulePermission: boolean;
  profession?: string;
}) => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const [visibleSchedule, setVisbileSchedule] = useState(false);
  return (
    <>
      <Popup visible={visible} onClose={onClose}>
        <Flex direction="column" gap={24} style={{width: 600}}>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Text typography="title20">Сотрудник</Text>
            <CloseButton onClose={() => onClose()} />
          </Flex>
          <NavLink
            icon={<Avatar size={56} url={url} />}
            label={name}
            description={profession}
            onClick={() => {
              if (employeesInfoPermission) {
                navigate(`/${username}/employee/${id}`);
              }
            }}
          />
          <Flex direction="column" gap={16}>
            {editWorkSchedulePermission && (
              <>
                <NavLink
                  label="Изменить рабочий день"
                  onClick={() => {
                    setVisbileSchedule(true);
                    onClose();
                  }}
                />
                <NavLink
                  label="Изменить график работы"
                  onClick={() => {
                    navigate(`/${username}/employee/${id}/schedule`);
                  }}
                />
              </>
            )}
          </Flex>
        </Flex>
      </Popup>
      <SettingSchedule
        employeeId={id!}
        name={name ?? 'Новый сотрудник'}
        url={url}
        visible={visibleSchedule}
        onClose={() => setVisbileSchedule(false)}
        selects={workingDate ? [workingDate] : []}
        isSelectable
      />
    </>
  );
};
