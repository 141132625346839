import axios from 'axios';

export type TariffTooltip = {title: string; tooltip: string | null};
export const tariffRows: TariffTooltip[] = [
  {title: '1 сотрудник', tooltip: null},
  {
    title: 'Все модули Booky',
    tooltip:
      'Полный комплект функций для управления вашим бизнесом, от онлайн-записи до учета рабочего времени сотрудников',
  },
  {
    title: 'Все интеграции Booky',
    tooltip:
      'Совместимость с популярными инструментами и приложениями. Список всегда пополняется',
  },
  {
    title: 'Управление с любого устройства',
    tooltip:
      'Удобство контроля вашего бизнеса везде и всегда. Полное управление с мобильного приложения',
  },
  {
    title: 'Техническая поддержка 24/7',
    tooltip: 'Поддержка круглосуточно без выходных.',
  },
  {
    title: 'Консультации по API',
    tooltip: 'Помощь в настройке и интеграции API.',
  },
];

export interface IPaymentResponse {
  subscription_id: string;
  response: {
    Model: {
      Status?: string;
      CardHolderMessage?: string;
      PaReq: string;
      AcsUrl: string;
      TransactionId: number;
    };
  };
}

interface IRequestProps {
  status: number;
  data: IPaymentResponse | {message: string};
}
interface requestJSONProps {
  token?: string | null;
  body?: BodyInit;
  action: 'change' | 'charge' | 'updateSubscription' | 'deleteCard';
}
export const requestJSON = async ({
  action,
  token,
  body,
}: requestJSONProps): Promise<IRequestProps> => {
  const response = await axios({
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : null,
    },
    url: process.env.REACT_APP_DOMAIN + '/api/payment/' + action,
    data: body,
    validateStatus: function (status) {
      return status < 400;
    },
  });
  const data = response.data;
  const status = response.status;

  return {status, data};
};
